<template>
  <div
    class="modal fade"
    id="modalAccountEstimated"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    v-if="isEditable"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Detail Akun</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Akun Perkiraan </label>
              <input
                type="text"
                class="form-control"
                v-model="formModal.nama"
                readonly
                placeholder="Masukkan Akun Perkiraan"
              />
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nilai </label>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  v-model="formModal.tipe"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  value="debit"
                />
                <label class="form-check-label mr-3" for="inlineRadio1"
                  >Debit</label
                >

                <input
                  class="form-check-input"
                  type="radio"
                  v-model="formModal.tipe"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  value="credit"
                />
                <label class="form-check-label" for="inlineRadio2"
                  >Kredit</label
                >
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label"></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  class="form-control text-right"
                  id="inlineFormInputGroupUsername"
                  placeholder="Masukan Nilai"
                  :model-modifiers="{ number: true }"
                  v-money="configMoney"
                  v-model.lazy="formModal.nilai"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-save" @click="saveDetail">
              Tambah Data
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ label }}  {{ isTransferBank ? 'Transfer Kas/Bank' : 'Jurnal Umum'}}</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link :to="{
                      name: isTransferBank ? 'TransferBank' : 'GeneralJournal'
                    }"
                      > {{ isTransferBank ? 'Transfer Kas/Bank' : 'Jurnal Umum'}}
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ label }}
                  </li>
                </ol>
              </nav>
            </div>

            <div
              class="button-edit"
              v-show="
                validated == 1 &&
                label == 'Edit' &&
                access &&
                formData.status == 'aktif'
              "
            >
              <button class="btn-add" type="button" @click="editable">
                <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                Edit
              </button>
            </div>
            <div v-show="validated != 1 || (label == 'Tambah' && access)">
              <button
                class="btn btn-save"
                :disabled="isSubmit || !isEditable"
                v-if="isEditable"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
            </div>
            <div v-show="formData.status == 'waiting' && (!isTransferBank && (checkAccess('kas_bank', 'agree_jurnal_general')) || (isTransferBank && checkAccess('kas_bank', 'agree_transfer_kasBank')))">
              <button
                class="btn btn-save mr-2"
                style="background: #ffa736; border-color: #ffa736"
                type="button"
                @click="accChange"
                :disabled="isSubmit"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Setujui
              </button>
              <button
                class="btn btn-save mr-2"
                style="background: #ff5252; border-color: #ff5252"
                type="button"
                @click="rejectChange"
                :disabled="isSubmit"
              >
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Tolak
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Detail {{ isTransferBank ? 'Transfer Kas/Bank' : 'Jurnal Umum'}}</div>
                <div class="sub-title">
                  {{ label }} informasi yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="mr-auto mb-3" v-if="formData.status == 'waiting'">
              <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link active"
                    id="pills-newdata-tab"
                    data-toggle="pill"
                    href="#pills-newdata"
                    role="tab"
                    aria-controls="pills-newdata"
                    aria-selected="true"
                    >New Data</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    id="pills-old-data-tab"
                    data-toggle="pill"
                    href="#pills-old-data"
                    role="tab"
                    aria-controls="pills-old-data"
                    aria-selected="false"
                    >Old Data</a
                  >
                </li>
              </ul>
            </div>

            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-newdata"
                role="tabpanel"
                aria-labelledby="pills-newdata-tab"
              >
                <div class="row">
                  <div class="col-12">
                    <div
                      v-if="tipeTransaksi == 16 || tipeTransaksi == 17"
                      class="alert alert-warning"
                    >
                      <strong>PERHATIAN!!</strong> data jurnal ini berkaitan
                      dengan Kasbon Nomor
                      <router-link
                        :to="{
                          name: 'DetailEmployeeCash',
                          params: {
                            id: formData.no_transaksi,
                          },
                        }"
                        >#{{ formData.no_transaksi }}</router-link
                      >
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Tanggal :</label>
                      <Datepicker
                        :autoApply="true"
                        v-model="date"
                        :format="format"
                        :maxDate="new Date()"
                        :class="{
                          'is-invalid': formError && formError.tanggal,
                        }"
                        :enableTimePicker="false"
                        :disabled="
                          !isEditable || (validated == 1 && label == 'Edit')
                        "
                        locale="id-ID"
                        selectText="Pilih"
                        cancelText="Batal"
                        @update:modelValue="changeDate"
                      ></Datepicker>
                      <div
                        class="form-error"
                        v-if="formError && formError.tanggal"
                      >
                        {{ formError.tanggal }}
                      </div>
                    </div>

                    <div class="form-group">
                      <label class="form-label">Departemen </label>
                      <Select2
                        v-model="formData.id_divisi"
                        :options="OptionDepartemen"
                        style="width: 100% !important"
                        placeholder="Pilih Departemen"
                        :settings="{
                          allowClear: !this.accessDepartement ? true : false,
                        }"
                        @change="formChange('id_divisi')"
                        @select="formChange('id_divisi')"
                        :disabled="
                          !isEditable || (validated == 1 && label == 'Edit')
                        "
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label class="form-label">Tipe Transaksi</label>
                      <Select2
                        v-model="formData.tipe_transaksi"
                        :options="access ? OptionTypeJurnal : OptionTypeJurnalAll"
                        style="width: 100% !important"
                        placeholder="Pilih Tipe Transaksi"
                        :settings="{
                          minimumResultsForSearch: -1,
                        }"
                        @change="formChange('tipe_transaksi')"
                        @select="formChange('tipe_transaksi')"
                        :disabled="
                          !isEditable || (validated == 1 && label == 'Edit')
                        "
                      />
                    </div>
                    <div class="form-group">
                      <label>Keterangan :</label>
                      <textarea
                        type="text"
                        class="form-control"
                        @keyup="formChange('keterangan')"
                        v-model="formData.keterangan"
                        placeholder="Masukkan Keterangan"
                        :disabled="
                          !isEditable || (validated == 1 && label == 'Edit')
                        "
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="title">Rincian Jurnal</div>
                    <div class="sub-title">
                      Rincian yang ada pada {{ this.isTransferBank ? 'transfer kas/bank' : 'jurnal umum' }}
                    </div>
                  </div>
                </div>
                <hr />
                <div class="d-flex justify-content-between">
                  <div class="search">
                    <div class="form-group" style="width: 400px">
                      <Select2
                        :disabled="validated == 1 && label == 'Edit'"
                        v-model="AccountEstimated"
                        :options="OptionAccountEstimated"
                        placeholder="Pilih akun perkiraan..."
                        :settings="{ templateResult: formatState }"
                        @change="formChange('')"
                        v-if="isEditable"
                        @select="mySelectAccountEstimated($event)"
                      />
                    </div>
                  </div>
                  <div class="card-total">
                    <div class="total-debit d-flex flex-column">
                      <span>Total Debit</span>
                      <span class="ml-auto"
                        >Rp {{ formatMoney(formData.debit) }}</span
                      >
                    </div>
                    <div class="total-kredit d-flex flex-column">
                      <span>Total Kredit</span>
                      <span class="ml-auto"
                        >Rp {{ formatMoney(formData.credit) }}</span
                      >
                    </div>
                  </div>
                </div>

                <div
                  v-if="
                    (formError && formError.debit) ||
                    (formError && formError.credit)
                  "
                >
                  <div
                    class="form-error"
                    :style="{ marginBottom: '5px', fontSize: '15px' }"
                  >
                    Masukan Terlebih dahulu rincian {{ this.isTransferBank ? 'transfer kas/bank' : 'jurnal umum' }}, serta total
                    credit dan total debit harus sama
                  </div>
                </div>

                <div class="table-responsive mt-4">
                  <table
                    class="table table-bordered table-striped"
                    id="tableDepartment"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Akun Perkiraan</th>
                        <th style="width: 20%">Nama Perkiraan</th>
                        <th style="width: 20%">Debit</th>
                        <th style="width: 20%">Kredit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in formDetail"
                        :key="index"
                        @click="
                          editDetail(
                            index,
                            value.akun,
                            value.nama,
                            value.nilai,
                            value.tipe,
                            $event
                          )
                        "
                        :style="{
                          cursor:
                            (validated != 1 && label == 'Edit') ||
                            label != 'Edit'
                              ? 'pointer'
                              : 'default',
                        }"
                      >
                        <td>{{ value.akun }}</td>
                        <td>{{ value.nama }}</td>
                        <td>
                          {{
                            value.tipe === "debit"
                              ? formatMoney(value.nilai)
                              : 0
                          }}
                        </td>
                        <td style="position: relative">
                          {{
                            value.tipe === "credit"
                              ? formatMoney(value.nilai)
                              : 0
                          }}
                          <img
                            v-if="
                              (validated != 1 && label == 'Edit') ||
                              label != 'Edit'
                            "
                            src="/img/avatar/icon-delete.svg"
                            alt=""
                            class="btn-add-row"
                            style="
                              position: absolute;
                              right: 10px;
                              width: 20px;
                              top: 50%;
                              transform: translateY(-50%);
                              cursor: pointer;
                            "
                          />
                        </td>
                      </tr>
                      <tr v-if="formDetail.length === 0">
                        <td colspan="4" class="text-center">
                          No data available in table
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-old-data"
                role="tabpanel"
                aria-labelledby="pills-old-data-tab"
              >
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>Tanggal :</label>
                      <Datepicker
                        :autoApply="true"
                        v-model="oldformData.tanggal"
                        :format="format"
                        :maxDate="new Date()"
                        :enableTimePicker="false"
                        :disabled="
                          !isEditable || (validated == 1 && label == 'Edit')
                        "
                        locale="id-ID"
                        selectText="Pilih"
                        cancelText="Batal"
                        @update:modelValue="changeDate"
                      ></Datepicker>
                    </div>

                    <div class="form-group">
                      <label class="form-label">Departemen </label>
                      <Select2
                        v-model="oldformData.id_divisi"
                        :options="OptionDepartemen"
                        style="width: 100% !important"
                        placeholder="Pilih Departemen"
                        :settings="{
                          allowClear: !this.accessDepartement ? true : false,
                        }"
                        @change="formChange('id_divisi')"
                        @select="formChange('id_divisi')"
                        :disabled="
                          !isEditable || (validated == 1 && label == 'Edit')
                        "
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label class="form-label">Tipe Transaksi</label>
                      <Select2
                        v-model="oldformData.tipe_transaksi"
                        :options="OptionTypeJurnal"
                        style="width: 100% !important"
                        placeholder="Pilih Tipe Transaksi"
                        :settings="{
                          minimumResultsForSearch: -1,
                        }"
                        @change="formChange('tipe_transaksi')"
                        @select="formChange('tipe_transaksi')"
                        :disabled="
                          !isEditable || (validated == 1 && label == 'Edit')
                        "
                      />
                    </div>
                    <div class="form-group">
                      <label>Keterangan :</label>
                      <textarea
                        type="text"
                        class="form-control"
                        @keyup="formChange('keterangan')"
                        v-model="oldformData.keterangan"
                        placeholder="Masukkan Keterangan"
                        :disabled="
                          !isEditable || (validated == 1 && label == 'Edit')
                        "
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="title">Rincian Jurnal</div>
                    <div class="sub-title">
                      Rincian yang ada pada {{ this.isTransferBank ? 'transfer kas/bank' : 'jurnal umum' }}
                    </div>
                  </div>
                </div>
                <hr />
                <div class="d-flex justify-content-end">
                  <div class="card-total">
                    <div class="total-debit d-flex flex-column">
                      <span>Total Debit</span>
                      <span class="ml-auto"
                        >Rp {{ formatMoney(oldformData.debit) }}</span
                      >
                    </div>
                    <div class="total-kredit d-flex flex-column">
                      <span>Total Kredit</span>
                      <span class="ml-auto"
                        >Rp {{ formatMoney(oldformData.credit) }}</span
                      >
                    </div>
                  </div>
                </div>

                <div class="table-responsive mt-4">
                  <table
                    class="table table-bordered table-striped"
                    id="tableDepartment"
                  >
                    <thead>
                      <tr>
                        <th style="width: 15%">Akun Perkiraan</th>
                        <th style="width: 20%">Nama Perkiraan</th>
                        <th style="width: 20%">Debit</th>
                        <th style="width: 20%">Kredit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(value, index) in oldFormDetail" :key="index">
                        <td>{{ value.akun }}</td>
                        <td>{{ value.nama }}</td>
                        <td>
                          {{
                            value.tipe === "debit"
                              ? formatMoney(value.nilai)
                              : 0
                          }}
                        </td>
                        <td style="position: relative">
                          {{
                            value.tipe === "credit"
                              ? formatMoney(value.nilai)
                              : 0
                          }}
                        </td>
                      </tr>
                      <tr v-if="oldFormDetail.length === 0">
                        <td colspan="4" class="text-center">
                          No data available in table
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../../src/components/Sidebar.vue";
import NavbarDashboard from "../../../../src/components/Navbar.vue";
import Datepicker from "vue3-date-time-picker";
import $ from "jquery";
import { ref } from "vue";
import { loadScript } from "vue-plugin-load-script";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");

import { get_AkunList } from "../../../actions/akun_perkiraan";
import {
  checkRules,
  cksClient,
  showAlert,
  checkModuleAccess,
} from "../../../helper";
import { VMoney } from "v-money";
import moment from "moment";
import "moment/locale/id";
import { get_ListDivisi } from "../../../actions/master";
import {
  get_JurnalUmumDetail,
  post_JurnalUmumSave,
  get_ListTransaksiTipeForJurnal,
} from "../../../actions/jurnal_umum";

export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
  },

  directives: {
    money: VMoney,
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  data() {
    return {
      access: true,
      checkAccess: checkModuleAccess,
      accessDepartement: cksClient().get("_account").akses_departement,
      isTransferBank: this.$route.name == "DetailTransferBank" || this.$route.name == "InsertTransferBank" ? true : false,
      validated: 1,
      myValue: "aaaa",
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      OptionKasBank: [],
      OptionDepartemen: [],
      OptionAccountEstimated: [],
      OptionTypeJurnal: [],
      OptionTypeJurnalAll: [],
      no_transaksi: this.$route.params.id,
      label: this.$route.params.id ? "Edit" : "Tambah",
      id_company: cksClient().get("_account").id_company,
      formModal: {
        key: "",
        id: "",
        nama: "",
        nilai: "",
        tipe: "debit",
      },
      formData: {
        no_transaksi: this.$route.params.id ? this.$route.params.id : "",
        id_company: cksClient().get("_account").id_company,
        id_divisi: "",
        debit: 0,
        credit: 0,
        tanggal: moment().format("YYYY-MM-DD"),
        keterangan: "",
        status: "aktif",
        no_jurnal: "",
        tipe_transaksi: this.$route.name == "DetailTransferBank" || this.$route.name == "InsertTransferBank" ? 38 : 5,
      },
      formError: [],
      rules: {
        debit: {
          required: true,
          min: 1,
        },
        credit: {
          required: true,
          min: 1,
          equalTo: "debit",
        },
        tanggal: {
          required: true,
          changed: true,
        },
        status: {
          required: true,
        },
        tipe_transaksi: {
          required: true,
        },
      },
      oldformData: {
        no_transaksi: "",
        id_company: "",
        id_divisi: "",
        id_akun: "",
        nilai: 0,
        tanggal: "",
        no_referensi: "",
        keterangan: "",
        status: "",
        no_jurnal: "",
        tipe_transaksi: 5,
      },
      oldFormDetail: [],
      AccountEstimated: "",
      onModal: false,
      formDetail: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      isSubmit: false,
      isEditable: true,
      tipeTransaksi: 5,
      enableEdit: [5, 29, 31, 32, 33, 34, 35, 36, 37, 38],
    };
  },

  created() {
    if (this.label == "Tambah" && this.accessDepartement) {
      var data = this.accessDepartement.split("|");
      this.formData.id_divisi = data[0];
    }
    if (this.no_transaksi) {
      this.getData();
    }
    this.getTipe();
    this.getKasBank(this.isTransferBank ? '1' : '');
    this.getDevisi();
    if (this.isTransferBank) {
      if (!this.checkAccess("kas_bank", "cru_transfer_kasBank")) {
        this.isEditable = false;
        this.access = false;
      }
    } else {
      if (!this.checkAccess("kas_bank", "cru_jurnal_general")) {
        this.isEditable = false;
        this.access = false;
      }
    }
    // this.FormatState()
    //  this.myChangeEvent()
    //  this.mySelectEvent()
  },

  mounted() {
    setTimeout(() => {
      $("#pills-new-data-tab").tab("show");
      $("#pills-new-data").addClass("active show");
    }, 600);
  },

  methods: {
    editable() {
      this.validated = 2;
    },
    getTipe() {
      get_ListTransaksiTipeForJurnal({
        // type: this.isTransferBank ? "jurnal transfer" : "jurnal"
        type: ''
      }, (res) => {
        const { list } = res;
        this.OptionTypeJurnal = []
        this.OptionTypeJurnalAll = list;
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            if (this.enableEdit.indexOf(element.id) >= 0 && element.id != 38) {
              this.OptionTypeJurnal.push(element);
            } else if (this.isTransferBank && element.id == 38) {
              this.OptionTypeJurnal.push(element);
            }
          }
        }
      });
    },
    getData() {
      get_JurnalUmumDetail(
        this.no_transaksi,
        (res) => {
          const { detail, data, oldData } = res;
          this.formData = { ...this.formData, ...data };
          this.changeDate(data.tanggal);
          this.date = data.tanggal;
          this.isEditable = this.enableEdit.indexOf(data.tipe_transaksi) >= 0
              ? true
              : false;
          this.tipeTransaksi = data.tipe_transaksi;
          if (data.tipe_transaksi == 16) {
            this.OptionTypeJurnal.push({
              id: 16,
              text: "Kasbon",
            });
          } else if (data.tipe_transaksi == 17) {
            this.OptionTypeJurnal.push({
              id: 17,
              text: "Pemabayaran Kasbon",
            });
          }
          delete this.formData.created_at;
          delete this.formData.updated_at;
          for (const key in detail) {
            if (Object.hasOwnProperty.call(detail, key)) {
              var element = detail[key];
              this.formData.no_jurnal = element.no_jurnal;
              delete element.no_jurnal;
              this.formDetail.push(element);
            }
          }

          if (this.formData.status == "aktif") {
            this.oldformData = { ...this.oldformData, ...this.formData };
            this.oldFormDetail = [...this.formDetail];
          } else if (this.formData.status == "waiting") {
            this.statusCompany.push({
              id: "waiting",
              text: "Menunggu Persetujuan",
            });
            this.oldformData = oldData.data;
            this.oldFormDetail = oldData.detail;
          }

          if (!this.enableEdit.includes(data.tipe_transaksi)) {
            this.access = false
          }
          this.countDetail();
        },
        () => {
          // showAlert(this.$swal, {
          //   title: "PERHATIAN!",
          //   msg: `Data ${ this.isTransferBank ? 'Transfer Kas/Bank' : 'Jurnal Umum'} tidak ditemukan`,
          //   showCancelButton: true,
          //   showConfirmButton: false,
          //   onCancel: () => {
          //     this.$router.push({
          //       name: this.isTransferBank ? "TransferBank" : "GeneralJournal",
          //     }); 
          //   },
          // });
        }
      );
    },
    getDevisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.OptionDepartemen = list;
      });
    },
    getKasBank(tipe = "") {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }

          this.OptionAccountEstimated = data;
        }
      );
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },
    async formChange(val) {
      if (val === "id_divisi" && this.accessDepartement) {
        this.formDetail = [];
        this.formData.debit = 0;
        this.formData.credit = 0;
        this.countDetail();
      }
      if (Object.hasOwnProperty.call(this.rules, val)) {
        this.rules[val].changed = true;
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (val === "id_divisi" && this.accessDepartement) {
        delete this.formError.debit;
        delete this.formError.credit;
      }
      this.checkHasBeenEdit();
    },

    formatMoney(a) {
      if (!a) return 0;
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    changeDate(data) {
      this.formData.tanggal = moment(data).format("YYYY-MM-DD");
    },
    saveDetail() {
      if (this.formModal.nilai) {
        if (this.formModal.key !== "" && this.formModal.key >= 0) {
          this.formDetail[this.formModal.key] = {
            akun: this.formModal.id,
            nama: this.formModal.nama,
            tipe: this.formModal.tipe,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          };
        } else {
          this.formDetail.push({
            akun: this.formModal.id,
            nama: this.formModal.nama,
            tipe: this.formModal.tipe,
            nilai: this.formModal.nilai.replace(/\./g, ""),
          });
        }
        this.countDetail();
        $("#modalAccountEstimated").modal("hide");
      }
    },
    editDetail(key, id, nama, nilai, tipe, ev) {
      if (
        (this.validated != 1 && this.label == "Edit") ||
        this.label != "Edit"
      ) {
        if (ev.target && ev.target.className === "btn-add-row") {
          this.deleteDetail(key);
        } else {
          this.formModal.key = key;
          this.formModal.id = id;
          this.formModal.nilai = nilai;
          this.formModal.nama = nama;
          this.formModal.tipe = tipe;
          $("#modalAccountEstimated").modal("show");
        }
      }
    },
    deleteDetail(key) {
      // delete this.formDetail[key];
      // this.formDetail.length -= 1;
      this.formDetail.splice(key, 1);
      this.countDetail();
    },
    countDetail() {
      var credit = 0;
      var debit = 0;
      for (const key in this.formDetail) {
        if (Object.hasOwnProperty.call(this.formDetail, key)) {
          const element = this.formDetail[key];
          if (element.tipe == "credit") {
            credit += parseInt(element.nilai);
          } else {
            debit += parseInt(element.nilai);
          }
        }
      }
      this.rules.credit.changed = true;
      this.rules.debit.changed = true;
      this.formData.credit = credit;
      this.formData.debit = debit;
      this.formChange();
    },
    mySelectAccountEstimated(ev) {
      if (ev.id) {
        this.onModal = true;
        this.formModal.key = "";
        this.formModal.id = ev.id;
        this.formModal.nilai = "";
        this.formModal.tipe = "debit";
        this.formModal.nama = ev.text;
        $("#modalAccountEstimated").modal("show");
        setTimeout(() => {
          this.AccountEstimated = "";
        }, 500);
      }
    },
    checkHasBeenEdit() {
      var isEdit = false;
      var formData = { ...this.formData };
      var oldformData = { ...this.oldformData };
      var formDetail = { ...this.formDetail };
      var oldFormDetail = { ...this.formDetail };

      delete formData.is_edit;
      delete oldformData.is_edit;

      if (
        (JSON.stringify(formData) != JSON.stringify(oldformData) ||
          JSON.stringify(formDetail) != JSON.stringify(oldFormDetail)) &&
        this.label == "Edit"
      ) {
        isEdit = true;
      }

      this.formData.is_edit = isEdit;
    },
    postData(status = "") {
      this.isSubmit = true;
      var data = { ...this.formData };
      data.jurnal_detail = this.formDetail;
      if (status != "") {
        data.status = status;
      }
      post_JurnalUmumSave(
        data,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? `Data ${ this.isTransferBank ? 'Transfer Kas/Bank' : 'Jurnal Umum'} berhasil dicatat`
              : `Data ${ this.isTransferBank ? 'Transfer Kas/Bank' : 'Jurnal Umum'} berhasil diperbarui`;
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({
                name: this.isTransferBank ? "TransferBank" : "GeneralJournal",
              });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.no_transaksi
            ? this.formData.is_edit
              ? "Anda akan mengubah data, anda membutuhkan persetujuan agar data tersimpan dengan benar.<br> Apakah anda akan melanjutkannya?"
              : "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    accChange() {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: "Apakah anda yakin, Tindakan ini akan menyetujui semua data dan akan tersimpan real?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          this.postData("disetujui");
        },
      });
    },

    rejectChange() {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: "Apakah anda yakin, Tindakan ini akan menolak semua data dan data akan dikembalikan lagi sebelum terjadinya perubahan?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          this.postData("ditolak");
        },
      });
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}
</style>
